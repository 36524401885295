import {ClassroomMessageArray} from "domain";

const initState = {
    list: new ClassroomMessageArray(),
    isListLoading: false,
};

export default function classroomMessageReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_CLASSROOM_MESSAGE_PENDING': {
            return { ...initState };
        }

        case 'LIST_CLASSROOM_MESSAGE_PENDING': {
            return { ...state, isListLoading: true };
        }

        case 'LIST_CLASSROOM_MESSAGE_REJECTED': {
            return { ...state, isListLoading: false };
        }

        case 'LIST_CLASSROOM_MESSAGE_FULFILLED': {
            return { ...state, list: new ClassroomMessageArray(payload), isListLoading: false };
        }

        case 'SET_CLASSROOM_MESSAGE_FULFILLED':
        case 'SAVE_CLASSROOM_MESSAGE_FULFILLED': {
            return {...state, list: new ClassroomMessageArray(state.list).addUpdate(payload) };
        }

        default: {
            return state;
        }
    }
}
