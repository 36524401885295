import BasicDomain from "./BasicDomain";

export default class EnrollmentRequest extends BasicDomain {

    static STATUS_PENDING = 'PENDING';

    static STATUS_ACCEPTED = 'ACCEPTED';

    static STATUS_REJECTED = 'REJECTED';

    static STATUSES = [
        EnrollmentRequest.STATUS_PENDING,
        EnrollmentRequest.STATUS_ACCEPTED,
        EnrollmentRequest.STATUS_REJECTED,
    ]

    static STATUS_LABELS = {
        [EnrollmentRequest.STATUS_PENDING]: 'Pending',
        [EnrollmentRequest.STATUS_ACCEPTED]: 'Accepted',
        [EnrollmentRequest.STATUS_REJECTED]: 'Rejected',
    }

    static DEFAULTS = {
        requester: null,
        course: null,
        message: '',
        status: EnrollmentRequest.STATUS_PENDING,
    }

    constructor(props) {
        super('EnrollmentRequest', props, EnrollmentRequest.DEFAULTS);
    }

    isSavable = () => (
        this.requester != null
        && this.course != null
        && EnrollmentRequest.STATUSES.includes(this.status)
    );
}

global.Parse.Object.registerSubclass('EnrollmentRequest', EnrollmentRequest);
