import {MessageArray, Message} from "domain";

const initState = {
    list: new MessageArray(),
    isListLoading: false,

    current: null,
    isLoading: false,
};

export default function messageReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_MESSAGE_PENDING': {
            return { ...initState };
        }

        case 'LIST_MESSAGE_PENDING': {
            return { ...state, isListLoading: true };
        }

        case 'LIST_MESSAGE_REJECTED': {
            return { ...state, isListLoading: false };
        }

        case 'LIST_MESSAGE_FULFILLED': {
            return { ...state, list: new MessageArray(payload), isListLoading: false };
        }

        case 'SAVE_MESSAGE_FULFILLED': {
            return {...state, list: state.list.addUpdate(payload), current: new Message(payload) };
        }

        case 'GET_MESSAGE_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'GET_MESSAGE_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'GET_MESSAGE_FULFILLED': {
            const newMessage = new Message(payload);
            return { ...state, current: newMessage, list: state.list.addUpdate(newMessage), isLoading: false };
        }


        case 'SET_MESSAGE_FULFILLED': {
            const { current } = state;
            const newMessage = new Message(payload);

            if (newMessage?.id) {
                if (current && current.id === newMessage.id) {
                    return { ...state, current: newMessage, list: state.list.addUpdate(newMessage), isLoading: false };
                }

                return { ...state, list: state.list.addUpdate(newMessage), isLoading: false };
            }

            return state;
        }

        default: {
            return state;
        }
    }
}
