import BasicDomain from "./BasicDomain";

export default class Classroom extends BasicDomain {

    static STATUS_IN_PROGRESS = 'IN_PROGRESS';

    static STATUS_COMPLETED = 'COMPLETED';

    static STATUS_CANCELLED = 'CANCELLED';

    static STATUS_DISABLED = 'DISABLED';

    static STATUSES = [
        Classroom.STATUS_IN_PROGRESS,
        Classroom.STATUS_COMPLETED,
        Classroom.STATUS_CANCELLED,
        Classroom.STATUS_DISABLED,
    ]

    static STATUS_LABELS = {
        [Classroom.STATUS_IN_PROGRESS]: 'In Progress',
        [Classroom.STATUS_COMPLETED]: 'Completed',
        [Classroom.STATUS_CANCELLED]: 'Cancelled',
        [Classroom.STATUS_DISABLED]: 'Disabled',
    }

    static DEFAULTS = {
        student: null,
        tutor: null,
        course: null,
        status: Classroom.STATUS_IN_PROGRESS,
    }

    constructor(props) {
        super('Classroom', props, Classroom.DEFAULTS);
    }

    isSavable = () => (
        this.student != null
        && this.tutor != null
        && this.course != null
        && Classroom.STATUSES.includes(this.status)
    );
}

global.Parse.Object.registerSubclass('Classroom', Classroom);
