import {ClassroomArray, Classroom} from "domain";

const initState = {
    list: new ClassroomArray(),
    listId: null,
    isListLoading: false,

    current: null,
    isLoading: false,
};

export default function classroomReducer(state = initState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case 'CLEAR_CLASSROOM_PENDING': {
            return { ...initState };
        }

        case 'FIND_CLASSROOM_PENDING': {
            return { ...state, isListLoading: true };
        }

        case 'FIND_CLASSROOM_REJECTED': {
            return { ...state, isListLoading: false };
        }

        case 'FIND_CLASSROOM_FULFILLED': {
            return {
                ...state,
                list: new ClassroomArray(payload),
                isListLoading: false,
                listId: meta.student?.id ?? meta.course?.id ?? meta.tutor?.id
            };
        }

        case 'GET_CLASSROOM_PENDING': {
            return {...state, isLoading: true };
        }

        case 'SET_CLASSROOM_REJECTED':
        case 'GET_CLASSROOM_REJECTED': {
            return {...state, isLoading: true };
        }

        case 'SET_CLASSROOM_FULFILLED':
        case 'GET_CLASSROOM_FULFILLED': {
            return { ...state, current: new Classroom(payload), isLoading: false };
        }

        default: {
            return state;
        }
    }
}
