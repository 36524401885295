import {MaterialArray, Material} from "domain";

const initState = {
    list: new MaterialArray(),
    listSectionId: null,
    current: null,
    isLoading: false,
    isLoaded: false,
};

export default function materialReducer(state = initState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case 'CLEAR_MATERIAL_PENDING': {
            return { ...initState };
        }

        case 'LIST_MATERIAL_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_MATERIAL_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_MATERIAL_FULFILLED': {
            const { section } = meta;
            return { ...state,
                list: new MaterialArray(payload),
                listSectionId: section?.id,
                isLoaded: true,
                isLoading: false,
            };
        }

        case 'SAVE_MATERIAL_FULFILLED': {
            const { listSectionId } = state;
            const newMaterial = new Material(payload);

            if (listSectionId !== newMaterial.section?.id) {
                return state;
            }

            return {
                ...state,
                list: newMaterial.status === Material.STATUS_ACTIVE
                    ? state.list.addUpdate(payload)
                    : state.list.remove(payload),
                current: new Material(payload)
            };

        }

        case 'SET_MATERIAL_FULFILLED':
        case 'GET_MATERIAL_FULFILLED': {
            return { ...state, current: new Material(payload) };
        }

        default: {
            return state;
        }
    }
}
