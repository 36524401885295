import BasicArray from './BasicArray';
import Material from "domain/Material";

export default class MaterialArray extends BasicArray {
    get myItemClass() { return Material; }

    constructor(items = []) {
        super(items);
    }
}
