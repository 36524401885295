import BasicArray from './BasicArray';
import Message from "./Message";

export default class MessageArray extends BasicArray {
    get myItemClass() { return Message; }

    constructor(items = []) {
        super(items);
    }

    /**
     * Counts how many message have a type equal to the provided status.
     *
     * @param {string} status - The status to count. Should be one of `Message.STATUSES`.
     * @returns {number} - The count of items with the specified status.
     */
    countStatusOf = (status) => (
        this.reduce((count, item) => {
            if (item.status === status) {
                count++;
            }
            return count;
        }, 0)
    )
}
