import BasicArray from './BasicArray';
import EnrollmentRequest from "./EnrollmentRequest";

export default class EnrollmentRequestArray extends BasicArray {
    get myItemClass() { return EnrollmentRequest; }

    constructor(items = []) {
        super(items);
    }
}
