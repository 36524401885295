import BasicArray from './BasicArray';
import ClassroomMessage from "./ClassroomMessage";

export default class ClassroomMessageArray extends BasicArray {
    get myItemClass() { return ClassroomMessage; }

    constructor(items = []) {
        super(items);
    }
}
