import {CourseArray, Course} from "domain";

const initState = {
    list: new CourseArray(),
    isListLoading: false,

    current: null,
    isLoading: false,
};

export default function courseReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_COURSE_PENDING': {
            return { ...initState };
        }

        case 'FIND_COURSE_PENDING': {
            return { ...state, isListLoading: true };
        }

        case 'FIND_COURSE_REJECTED': {
            return { ...state, isListLoading: false };
        }

        case 'FIND_COURSE_FULFILLED': {
            return { ...state, list: new CourseArray(payload), isListLoading: false };
        }

        case 'GET_COURSE_PENDING':
        case 'SAVE_COURSE_PENDING': {
            return {...state, isLoading: true };
        }

        case 'SET_COURSE_REJECTED':
        case 'GET_COURSE_REJECTED':
        case 'SAVE_COURSE_REJECTED': {
            return {...state, isLoading: true };
        }

        case 'SAVE_COURSE_FULFILLED': {
            return {...state, list: state.list.update(payload), current: new Course(payload), isLoading: false };
        }

        case 'SET_COURSE_FULFILLED':
        case 'GET_COURSE_FULFILLED': {
            return { ...state, current: new Course(payload), isLoading: false };
        }

        default: {
            return state;
        }
    }
}
