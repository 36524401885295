import BasicArray from './BasicArray';
import Notice from './Notice';

export default class NoticeArray extends BasicArray {
    get myItemClass() { return Notice; }

    constructor(items = []) {
        super(items);
    }

    clone = () => new NoticeArray(this.filter((e) => (e.active || e.getSecondsOld() < 60)));
}
