import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';
import { GlobalStyles } from '@mui/system';

import reportWebVitals from './reportWebVitals';
import {initialize} from './utils/ParseUtils';
import createAppStore from './store';
import theme from './theme';
import globalStyles from './globalStyles';

import Authorize from 'components/core/Authorize';
import Layout from 'components/core/Layout';
import ClassroomLayout from "components/classroom/ClassroomLayout";
import CoursesView from "views/course/CoursesView";
import CourseView from "views/course/CourseView";
import ClassroomsView from "views/classroom/ClassroomsView";
import ProfileView from "views/profile/ProfileView";
import SubscriptionView from "views/profile/SubscriptionView";
import NotFoundView from 'views/NotFoundView';
import SignUpView from "views/SignUpView";
import ForgotPasswordView from "views/ForgotPasswordView";
import InboxView from "views/message/InboxView";
import MessageView from "views/message/MessageView";
import CourseNewView from "views/course/CourseNewView";
import SectionView from "views/course/section/SectionView";
import MaterialView from "views/course/section/material/MaterialView";
import ClassroomView from "views/classroom/ClassroomView";

initialize();
const store = createAppStore();
const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <CssVarsProvider theme={theme} defaultMode="light">
            <GlobalStyles styles={globalStyles(theme)} />
            <CssBaseline />
            <BrowserRouter>
                <Routes>
                    <Route element={<Layout />}>
                        <Route exact path="/" element={<ClassroomsView />} />
                        <Route exact path="/course" element={<CoursesView />} />
                        <Route exact path="/course/:courseId" element={<CourseView />} />
                        <Route exact path="/signup" element={<SignUpView />} />
                        <Route exact path="/forgot-password" element={<ForgotPasswordView />} />
                        <Route element={<Authorize />}>
                            <Route exact path="/subscription" element={<SubscriptionView />} />
                            <Route exact path="/profile/:profileId" element={<ProfileView />} />
                            <Route exact path="/course/new" element={<CourseNewView />} />
                            <Route exact path="/course/:courseId/section/:sectionId" element={<SectionView />} />
                            <Route exact path="/course/:courseId/section/:sectionId/:materialId" element={<MaterialView />} />
                            <Route exact path="/message" element={<InboxView />} />
                            <Route exact path="/message/:messageId" element={<MessageView />} />
                            <Route exact path="/classroom" element={<ClassroomsView />} />
                            <Route exact path="/classroom/:courseId" element={<ClassroomsView />} />
                            <Route element={<ClassroomLayout />}>
                                <Route exact path="/classroom/:courseId/:classroomId" element={<ClassroomView />} />
                                <Route exact path="/classroom/:courseId/:classroomId/:sectionId" element={<SectionView />} />
                                <Route exact path="/classroom/:courseId/:classroomId/section/:sectionId" element={<SectionView />} />
                                <Route exact path="/classroom/:courseId/:classroomId/:sectionId/:materialId" element={<MaterialView />} />
                            </Route>

                        </Route>
                        <Route path="*" element={<NotFoundView />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </CssVarsProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
