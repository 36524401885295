import BasicDomain from "./BasicDomain";
import languageConfig from '../languageConfig.json';

export default class Course extends BasicDomain {

    static STATUS_ACTIVE = 'ACTIVE';

    static STATUS_INACTIVE = 'INACTIVE';

    static STATUS_CLOSED = 'CLOSED';

    static STATUSES = [
        Course.STATUS_ACTIVE,
        Course.STATUS_INACTIVE,
        Course.STATUS_CLOSED,
    ]

    static STATUS_LABELS = {
        [Course.STATUS_ACTIVE]: 'Active',
        [Course.STATUS_INACTIVE]: 'Inactive',
        [Course.STATUS_CLOSED]: 'Closed',
    }

    static LANGUAGES = Object.keys(languageConfig);
    static LANGUAGE_LABELS = languageConfig;

    static DEFAULTS = {
        name: '',
        tagline: '',
        description: '',
        language: 'EN',
        tutor: null,
        status: Course.STATUS_ACTIVE,
    }

    constructor(props) {
        super('Course', props, Course.DEFAULTS);
    }

    isSavable = () => (
        this.name != null
        && this.name.trim() !== ''
        && this.tagline != null
        && this.tagline.trim() !== ''
        && this.description != null
        && this.description.trim() !== ''
        && Course.LANGUAGES.includes(this.language)
        && Course.STATUSES.includes(this.status)
    );
}

global.Parse.Object.registerSubclass('Course', Course);
