import BasicDomain from "./BasicDomain";

export default class Section extends BasicDomain {

    static DEFAULTS = {
        name: '',
        summary: '',
        description: '',
        course: null,
    }

    constructor(props) {
        super('Section', props, Section.DEFAULTS);
    }

    isSavable = () => (
        this.name != null
        && this.name.trim() !== ''
        && this.summary != null
        && this.summary.trim() !== ''
    );
}

global.Parse.Object.registerSubclass('Section', Section);
