import BasicArray from './BasicArray';
import Section from "./Section";

export default class SectionArray extends BasicArray {
    get myItemClass() { return Section; }

    constructor(items = []) {
        super(items);
    }
}
