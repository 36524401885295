import {EnrollmentRequestArray, EnrollmentRequest} from "domain";

const initState = {
    listByCourse: new EnrollmentRequestArray(),
    listByCourseId: null,
    isLoadingListByCourse: false,

    listByProfile: new EnrollmentRequestArray(),
    listByProfileId: null,
    isLoadingListByProfile: false,

    current: null,
    isLoading: false,
};

export default function enrollmentRequestReducer(state = initState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case 'CLEAR_ENROLLMENT_REQUEST_PENDING': {
            return { ...initState };
        }

        case 'LIST_ENROLLMENT_REQUEST_BY_COURSE_PENDING': {
            return { ...state, isLoadingListByCourse: true };
        }

        case 'LIST_ENROLLMENT_REQUEST_BY_COURSE_REJECTED': {
            return { ...state, isLoadingListByCourse: false };
        }

        case 'LIST_ENROLLMENT_REQUEST_BY_COURSE_FULFILLED': {
            const { course } = meta;
            return { ...state,
                listByCourse: new EnrollmentRequestArray(payload),
                listByCourseId: course?.id,
                isLoadingListByCourse: false,
            };
        }

        case 'LIST_ENROLLMENT_REQUEST_BY_PROFILE_PENDING': {
            return { ...state, isLoadingListByProfile: true };
        }

        case 'LIST_ENROLLMENT_REQUEST_BY_PROFILE_REJECTED': {
            return { ...state, isLoadingListByProfile: false };
        }

        case 'LIST_ENROLLMENT_REQUEST_BY_PROFILE_FULFILLED': {
            const { course } = meta;
            return { ...state,
                listByProfile: new EnrollmentRequestArray(payload),
                listByProfileId: course?.id,
                isLoadingListByProfile: false,
            };
        }

        case 'SAVE_ENROLLMENT_REQUEST_FULFILLED': {
            const {
                listByCourseId,
                listByCourse: origListByCourse,
                listByProfileId,
                listByProfile: origListByProfile
            } = state;
            const current = new EnrollmentRequest(payload);

            const listByCourse = new EnrollmentRequestArray(origListByCourse)
            const listByProfile = new EnrollmentRequestArray(origListByProfile)

            if (listByCourseId && listByCourseId === current.course?.id) {
                listByCourse.addUpdate(current);
            }

            if (listByProfileId && listByProfileId === current.course?.id) {
                listByProfile.addUpdate(current);
            }

            return {
                ...state,
                listByCourse,
                listByProfile,
                current,
            };

        }

        case 'SET_ENROLLMENT_REQUEST_PENDING':
        case 'GET_ENROLLMENT_REQUEST_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'SET_ENROLLMENT_REQUEST_REJECTED':
        case 'GET_ENROLLMENT_REQUEST_REJECTED': {
            return { ...state, isLoading: true };
        }

        case 'SET_ENROLLMENT_REQUEST_FULFILLED':
        case 'GET_ENROLLMENT_REQUEST_FULFILLED': {
            return { ...state, current: new EnrollmentRequest(payload), isLoading: false };
        }

        default: {
            return state;
        }
    }
}
