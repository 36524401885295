import BasicDomain from './BasicDomain';

export default class Message extends BasicDomain {
    static STATUS_UNREAD = 'UNREAD';

    static STATUS_READ = 'READ';

    static STATUS_ARCHIVED = 'ARCHIVED';

    static STATUSES = [
        Message.STATUS_UNREAD,
        Message.STATUS_READ,
        Message.STATUS_ARCHIVED,
    ]

    static DEFAULTS = {
        to: null,
        group: null,
        from: null,
        subject: '',
        body: '',
        sent: new Date(),
        status: Message.STATUS_UNREAD,
    }

    constructor(props) {
        super(
            'Message',
            props,
            Message.DEFAULTS
        );
    }

    getLabel = () => this.subject;

    equals = (obj) => (obj instanceof Message && this.id === obj.id);

    toString = () => this.getLabel();
}

global.Parse.Object.registerSubclass('Message', Message);
