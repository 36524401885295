import BasicDomain from './BasicDomain';

export default class ClassroomMessage extends BasicDomain {
    static STATUS_UNREAD = 'UNREAD';

    static STATUS_READ = 'READ';

    static STATUS_ARCHIVED = 'ARCHIVED';

    static STATUSES = [
        ClassroomMessage.STATUS_UNREAD,
        ClassroomMessage.STATUS_READ,
        ClassroomMessage.STATUS_ARCHIVED,
    ]

    static DEFAULTS = {
        sender: null,
        classroom: null,
        text: '',
        sent: new Date(),
        status: ClassroomMessage.STATUS_UNREAD,
    }

    constructor(props) {
        super(
            'ClassroomMessage',
            props,
            ClassroomMessage.DEFAULTS
        );
    }

    equals = (obj) => (obj instanceof ClassroomMessage && this.id === obj.id);
}

global.Parse.Object.registerSubclass('ClassroomMessage', ClassroomMessage);
