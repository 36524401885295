import BasicDomain from "./BasicDomain";
import * as yup from 'yup';

export default class Profile extends BasicDomain {

    static STATUS_ACTIVE = 'ACTIVE';

    static STATUS_INACTIVE = 'INACTIVE';

    static STATUSES = [
        Profile.STATUS_ACTIVE,
        Profile.STATUS_INACTIVE,
    ]

    static STATUS_LABELS = {
        [Profile.STATUS_ACTIVE]: 'Active',
        [Profile.STATUS_INACTIVE]: 'Inactive',
    }

    static TYPE_STUDENT = 'STUDENT';

    static TYPE_TUTOR = 'TUTOR';

    static TYPE_ADMIN = 'ADMIN';

    static TYPES = [
        Profile.TYPE_STUDENT,
        Profile.TYPE_TUTOR,
        Profile.TYPE_ADMIN,
    ]

    static TYPE_LABELS = {
        [Profile.TYPE_STUDENT]: 'Student',
        [Profile.TYPE_TUTOR]: 'Tutor',
        [Profile.TYPE_ADMIN]: 'Admin',
    }


    static BILLING_STATUS_INACTIVE = 'INACTIVE'; // Meaning this account has never been activated

    static BILLING_STATUS_ACTIVE = 'ACTIVE'; // An active (subscription) account.

    static BILLING_STATUS_CANCELED = 'CANCELED'; // Customer canceled subscription, but account has not been deactivated yet.

    static BILLING_STATUS_DEACTIVATED = 'DEACTIVATED'; // User deactivated their account.

    static BILLING_STATUSES = [
        Profile.BILLING_STATUS_ACTIVE,
        Profile.BILLING_STATUS_INACTIVE,
        Profile.BILLING_STATUS_CANCELED,
        Profile.BILLING_STATUS_DEACTIVATED,
    ]

    static BILLING_STATUS_LABELS = {
        [Profile.BILLING_STATUS_ACTIVE]: 'Active',
        [Profile.BILLING_STATUS_INACTIVE]: 'Inactive',
        [Profile.BILLING_STATUS_CANCELED]: 'Canceled',
        [Profile.BILLING_STATUS_DEACTIVATED]: 'Deactivated',
    }

    static DEFAULTS = {
        name: '',
        tagline: '',
        bio: '',
        pic: null,
        user: null,
        cntUnreadMessages: 0,
        deactivatedAt: null,
        billingType: '',
        billingStatus: Profile.STATUS_INACTIVE,
        type: Profile.TYPE_STUDENT,
        status: Profile.STATUS_ACTIVE,
    }

    static VALIDATION_SCHEMA = yup.object({
        name: yup.string().required('Name is required'),
    }).required();

    constructor(props) {
        super('Profile', props, Profile.DEFAULTS);
    }


    get picUrl() {
        if (!this.pic) {
            return null;
        }

        if (typeof this.pic.url === 'function') {
            return this.pic.url();
        }

        return this.pic.url ?? null;
    }

    isSavable = () => (
        this.name != null
        && this.name.trim() !== ''
        && Profile.TYPES.includes(this.type)
        && Profile.STATUSES.includes(this.status)
    );

    inspect = () => {
        return Profile.VALIDATION_SCHEMA.validate(this, { abortEarly: false });
    }
}

global.Parse.Object.registerSubclass('Profile', Profile);
