import BasicArray from './BasicArray';
import Profile from "./Profile";

export default class ProfileArray extends BasicArray {
    get myItemClass() { return Profile; }

    constructor(items = []) {
        super(items);
    }
}
