import BasicDomain from "./BasicDomain";

import DocumentIcon from "@mui/icons-material/Article";
import FormIcon from "@mui/icons-material/DynamicForm";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import WebVideoIcon from "@mui/icons-material/SmartDisplay";
import WebUrlIcon from "@mui/icons-material/Web";

export default class Material extends BasicDomain {

    static STATUS_ACTIVE = 'ACTIVE';

    static STATUS_INACTIVE = 'INACTIVE';

    static STATUSES = [
        Material.STATUS_ACTIVE,
        Material.STATUS_INACTIVE,
    ]

    static STATUS_LABELS = {
        [Material.STATUS_ACTIVE]: 'Active',
        [Material.STATUS_INACTIVE]: 'Inactive',
    }

    static TYPE_DOC = 'DOCUMENT';

    static TYPE_FRM = 'FORM';

    static TYPE_ATC = 'ATTACHMENT';

    static TYPE_PDF = 'PDF';

    static TYPE_IMG = 'IMAGE';

    static TYPE_WVD = 'WEB_VIDEO';

    static TYPE_WEB = 'WEB';


    static TYPES = [
        Material.TYPE_DOC,
        // Material.TYPE_FRM,
        Material.TYPE_ATC,
        Material.TYPE_PDF,
        Material.TYPE_IMG,
        Material.TYPE_WVD,
        Material.TYPE_WEB,
    ]

    static TYPE_LABELS = {
        [Material.TYPE_DOC]: 'Document',
        [Material.TYPE_FRM]: 'Form',
        [Material.TYPE_ATC]: 'Attachment',
        [Material.TYPE_PDF]: 'PDF',
        [Material.TYPE_IMG]: 'Image',
        [Material.TYPE_WVD]: 'Video',
        [Material.TYPE_WEB]: 'Web URL',
    }

    static TYPES_ICONS = {
        [Material.TYPE_DOC]: DocumentIcon,
        [Material.TYPE_FRM]: FormIcon,
        [Material.TYPE_ATC]: AttachmentIcon,
        [Material.TYPE_PDF]: PdfIcon,
        [Material.TYPE_IMG]: ImageIcon,
        [Material.TYPE_WVD]: WebVideoIcon,
        [Material.TYPE_WEB]: WebUrlIcon,
    };

    static DEFAULTS = {
        name: '',
        section: null,
        status: Material.STATUS_ACTIVE,
        data: {},
        attachment: null,
        size: 0,
        type: Material.TYPE_DOC
    }

    constructor(props) {
        super('Material', props, Material.DEFAULTS);
    }

    isSavable = () => (
        this.name != null
        && this.name.trim() !== ''
        && Material.TYPES.includes(this.type)
    );
}

global.Parse.Object.registerSubclass('Material', Material);
