import BasicArray from './BasicArray';
import Course from "./Course";

export default class CourseArray extends BasicArray {
    get myItemClass() { return Course; }

    constructor(items = []) {
        super(items);
    }
}
