import {SectionArray, Section} from "domain";

const initState = {
    list: new SectionArray(),
    listCourseId: null,
    current: null,
    isLoading: false,
    isLoaded: false,
};

export default function sectionReducer(state = initState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case 'CLEAR_SECTION_PENDING': {
            return { ...initState };
        }

        case 'LIST_SECTION_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_SECTION_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_SECTION_FULFILLED': {
            const { course } = meta;
            return { ...state,
                list: new SectionArray(payload),
                listCourseId: course?.id,
                isLoaded: true,
                isLoading: false,
            };
        }

        case 'SAVE_SECTION_FULFILLED': {
            return {...state, list: state.list.update(payload), current: new Section(payload) };
        }

        case 'SET_SECTION_FULFILLED':
        case 'GET_SECTION_FULFILLED': {
            return { ...state, current: new Section(payload) };
        }

        default: {
            return state;
        }
    }
}
